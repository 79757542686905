import React from 'react';

export default function Home() {
  return (
    <main className='home'>
      <section style={{height: '700px', paddingTop: '55vh'}}>
        <div className="inner">
          <h1 className='is-arcade' style={{fontSize: '30rem', textAlign: 'center'}}>404</h1>
        </div>
      </section>
    </main>
  )
}
